export const vueI18n = {"fallbackLocale":"de","silentTranslationWarn":true,"silentFallbackWarn":true}
export const vueI18nLoader = false
export const locales = [{"code":"de","iso":"de-DE"},{"code":"en","iso":"en-US"},{"code":"it","iso":"it-IT"},{"code":"es","iso":"es-ES"},{"code":"fr","iso":"fr-FR"},{"code":"ru","iso":"ru-RU"},{"code":"zh","iso":"CN-BJ"},{"code":"nl","iso":"nl-NL"}]
export const defaultLocale = 'de'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = false
export const langDir = null
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = 'http://localhost:3000'
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = false
export const pages = {}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const encodePaths = false
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["de","en","it","es","fr","ru","zh","nl"]
export const trailingSlash = undefined
