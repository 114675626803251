//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from './Multiselect.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'LanguageBanner',
    components: { Multiselect },
    data() {
        return {
            oldState: false,
            show: false,
            selected: null,
            options: [],
        };
    },
    computed: {
        ...mapGetters(['getPageSlugs']),
        productRange() {
            return this.getSelectedProductRange().toLowerCase();
        },
    },
    watch: {
        show(value) {
            this.$emit('show', value);
            this.setCssVar();
        },
    },
    methods: {
        setCssVar() {
            this.$nextTick(() => {
                if (this.show) {
                    document.documentElement.style.setProperty('--banner-height', `${this.$refs.banner.getBoundingClientRect().height}px`);
                } else {
                    document.documentElement.style.setProperty('--banner-height', `0px`);
                }
            });
        },
        close() {
            this.show = false;
            this.setCookie(this.selected[0].code);
        },
        onChange(option) {
            if (option) {
                this.setCookie(option.code);
                const { slug } = option;
                if (slug) {
                    const urlParams = window.location.href.split('?')?.[1];
                    window.location = slug + `${urlParams ? '?' + urlParams : ''}`;
                }
            }
        },
        setCookie(code) {
            this.$cookies.set('base_language', code, {
                path: '/',
                maxAge: 60 * 60 * 24 * 30,
            });
        },
    },
    mounted() {
        const browserLang = navigator.language.split('-')[0];
        const cookieLang = this.$cookies.get('base_language');

        if (browserLang !== this.$i18n.locale && !cookieLang) {
            this.show = true;
        } else if (cookieLang && cookieLang !== this.$i18n.locale) {
            this.options.find((item) => {
                if (item.code === cookieLang) {
                    this.onChange(item);
                }
            });
        }

        this.setCssVar();

        this.$nuxt.$on('mobile-nav.open', () => {
            this.oldState = this.show;
            this.show = false;
        });
        this.$nuxt.$on('mobile-nav.close', () => {
            this.show = this.oldState;
        });
    },
    created() {
        if (!this.getPageSlugs) {
            return;
        }

        try {
            // map selectable options (languages)
            const options = Object.keys(this.getPageSlugs).map((key) => {
                return {
                    label: this.$i18n.t(`languageswitch.label.${key}`),
                    code: key,
                    slug: this.getPageSlugs[key],
                    value: key,
                };
            });

            if (options) {
                this.options = options.sort((a, b) => a?.label?.localeCompare(b?.label) ?? 0);
                this.disabled = options.length <= 1;

                // const preSelected = navigator.language.split('-').length ? navigator.language.split('-')[0].toLowerCase() : this.$i18n.locale;
                const preSelected = this.$i18n.locale;
                // set active locale
                this.selected = this.options.filter((item) => {
                    return item.code === preSelected;
                });
            }
        } catch (e) {
            console.error(e);
        }
    },
};
