import axios from 'axios';

export default ({ app, $config }, inject) => {
    /**
     * Returns the lowest price of the product related variants
     * @param product
     * @returns {*}
     */
    const getLowestVariantPrice = (product) => {
        let variantsSorted = null;
        if (product.variants && product.variants.length) {
            variantsSorted = [...product.variants].sort((a, b) => {
                if (a.price && b.price) {
                    return parseInt(a.price) - parseInt(b.price);
                }
            });
        }
        return variantsSorted && variantsSorted.length ? variantsSorted[0].price : null;
    };

    /**
     * Returns the amount of colors through the products variants
     * @param product
     * @returns {number}
     */
    const getProductColorAmount = (product) => {
        // get colors of product variants
        let colors = [];
        product.variants.forEach((variant) => {
            if (variant.colors) {
                colors = [...colors, variant.colors];
            }
        });
        // remove doublettes
        colors = colors.filter((thing, index) => {
            const color = JSON.stringify(thing);
            return (
                index ===
                colors.findIndex((obj) => {
                    return JSON.stringify(obj) === color;
                })
            );
        });
        return colors.length;
    };

    /**
     * Find menu item by page slug in current locale node
     * @param slug
     * @param currentNode
     * @returns {*}
     */
    const findMenuItemBySlug = (slug, currentNode) => {
        let i, currentChild, result;

        if (currentNode && `/${slug}` === currentNode.slug) {
            return currentNode;
        } else if (currentNode && currentNode.children) {
            for (i = 0; i < currentNode.children.length; i++) {
                currentChild = currentNode.children[i];
                result = findMenuItemBySlug(slug, currentChild);
                if (result !== false) {
                    return result;
                }
            }
        }
        return false;
    };

    /**
     * Find menu item by page id in current locale node
     * @param id
     * @param currentNode
     * @returns {*}
     */
    const findMenuItemById = (id, currentNode) => {
        let i, currentChild, result;
        if (currentNode && parseInt(id, 10) === parseInt(currentNode.id, 10)) {
            return currentNode;
        } else if (currentNode && currentNode.children) {
            for (i = 0; i < currentNode.children.length; i++) {
                currentChild = currentNode.children[i];

                result = findMenuItemById(id, currentChild);
                if (result !== false) {
                    return result;
                }
            }
        }
        return false;
    };

    const localizeSlug = (slug, locale) => {
        const _locale = locale || app.i18n.locale;
        return _locale !== app.i18n.defaultLocale && slug && !slug.startsWith('http') ? `/${_locale}${slug}` : slug;
    };

    const getLocalizedSlug = (id, locale) => {
        const _locale = locale || app.i18n.locale;
        const _slug = findMenuItemById(id, app.store.state.menu[_locale]).slug;
        return localizeSlug(_slug, _locale);
    };

    const localeSlug = (item, locale) => {
        const _locale = locale || app.i18n.locale;
        const slug = item.slugs && item.slugs[_locale]?.slug ? '/' + item.slugs?.[_locale]?.slug : item.slug;
        return localizeSlug(slug, locale);
    };

    /**
     * Get direct product link
     * @param productSlug
     * @param variantExternalKey
     * @param useAbsoluteUrl
     * @return {string|null}
     */
    const getProductLink = (productSlug, variantExternalKey = null, useAbsoluteUrl = false) => {
        const productPageProperties = app.store.getters.getStoredSiteProperties?.product_page;

        if (!productPageProperties || !productSlug) {
            return null;
        }

        let href = localeSlug(productPageProperties) + '/' + productSlug;

        // TODO: THIS MIGHT NOT EVEN WORK
        // if (variantExternalKey) {
        //     href = `${href}?variant=${variantExternalKey}`;
        // }

        // NOTE: Only works on the client
        if (useAbsoluteUrl && process.client) {
            return `${window.location.origin}${href}`;
        }

        return href;
    };

    const imagePath = $config.pimcoreUrl;

    const getImageSrcSet = (imageSource) => {
        if (!imageSource) return null;

        return [0, 1]
            .map((i) => {
                const res = imageSource?.resolutions?.[i];

                if (!res) return null;

                return `${imagePath}${res.url} ${res.resolution}x`;
            })
            .filter((o) => !!o)
            .join(', ');
    };

    const getBaseImageSrc = (image) => {
        if (!image) return null;

        const { url } = image?.resolutions?.[0];
        if (!url) return null;
        return `${imagePath}${url}`;
    };

    const mapImageMetadata = (image = {}) => {
        return {
            name: image?.metadata?.name,
            alt: image?.metadata?.alt,
            title: image?.metadata?.title,
            copyright: image?.metadata?.copyright,
        };
    };

    const mapImageData = (image) => {
        return {
            srcset: getImageSrcSet(image),
            srcsetLarge: getImageSrcSet(image?.srcset?.[0]),
            baseImg: getBaseImageSrc(image),
            ...mapImageMetadata(image),
        };
    };

    async function sendPardotForm(path, data) {
        const params = new URLSearchParams();

        for (const property in data) {
            params.append(property, data[property]);
        }

        const response = await axios.post(`${$config.pimcoreUrl}/${path}`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response?.data?.success ?? false;
    }

    inject('getLowestVariantPrice', getLowestVariantPrice); //TODO: move to getters.js
    inject('getProductColorAmount', getProductColorAmount); //TODO: move to getters.js
    inject('findMenuItemBySlug', findMenuItemBySlug);
    inject('findMenuItemById', findMenuItemById);
    inject('getLocalizedSlug', getLocalizedSlug);
    inject('localizeSlug', localizeSlug);
    inject('localeSlug', localeSlug);
    inject('mapImageData', mapImageData);
    inject('sendPardotForm', sendPardotForm);
    inject('getProductLink', getProductLink);
};
