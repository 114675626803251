export default function (req, res, next) {
    const host = req.headers['x-forwarded-host'] || req.headers.host;

    if (host === 'bette.hu') {
        const redirectUrl = `https://my-bette.com${req.url}`;
        res.writeHead(301, { Location: redirectUrl });
        return res.end();
    }

    if (req.url.endsWith('/') && req.url !== '/' && req.url !== '/de') {
        const urlWithoutTrailingSlash = req.url.slice(0, -1);
        const fullRedirectUrl = `http://${host}${urlWithoutTrailingSlash}`;
        res.writeHead(301, { Location: fullRedirectUrl });
        return res.end();
    }

    return next();
}
