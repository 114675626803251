//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Companylogo from '../Companylogo.vue';
import NavigationSubmenu from './NavigationSubmenu.vue';
import NavigationUtils from './NavigationUtils.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'NavigationMain',
    components: { NavigationUtils, NavigationSubmenu, Companylogo },
    computed: {
        ...mapGetters(['getStoredMenu']),
        getStoredMenuLeft() {
            return this.getStoredMenu[this.$i18n.locale].children.filter((item) => item.navigationPosition === 'left');
        },
        getStoredMenuRight() {
            return this.getStoredMenu[this.$i18n.locale].children.filter((item) => item.navigationPosition === 'right');
        },
    },
    data() {
        return {
            opened: null,
            header: null,
            expandedStatus: {},
        };
    },
    // watch: {
    //     opened(val) {
    //         if (val !== null) {
    //             this.$nuxt.$emit('navigation.state', { navOpen: false });
    //         } else {
    //             this.$nuxt.$emit('navigation.state', { navOpen: false });
    //         }
    //         return val;
    //     },
    // },
    mounted() {
        this.header = document.body.querySelector('header.header');

        // toggle "open"-state in navigation main links
        this.$nuxt.$on('submenu.closed', ({ id }) => {
            if (this.opened === id && this.$refs[`nav-${id}`]?.length) {
                this.opened = null;
                this.$refs[`nav-${id}`][0].focus();
            }
        });

        this.$nuxt.$on('search.toggle', ({ event }) => {
            this.handleSubmenu(event);
        });

        this.$nuxt.$on('submenu-closed', () => {
            this.opened = null;
            for (let id in this.expandedStatus) {
                this.expandedStatus[id] = false;
            }
        });
    },
    methods: {
        handleSubmenu(event) {
            const id = event.target.getAttribute('id');
            this.expandedStatus[id] = !this.expandedStatus[id];
            this.opened = event.target.getAttribute('id') === this.opened ? null : event.target.getAttribute('id');
            this.$nuxt.$emit('submenu.toggle', {
                toggle: this.opened ? event.target.parentNode.querySelector('.submenu') : null,
            });
        },
        closeSubmenu() {
            this.opened = null;
            this.$nuxt.$emit('submenu.toggle', {
                toggle: null,
            });
        },
        slug(id) {
            return this.$getLocalizedSlug(id);
        },
    },
};
