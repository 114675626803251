import { render, staticRenderFns } from "./NavigationMobileMain.vue?vue&type=template&id=4d95413b&"
import script from "./NavigationMobileMain.vue?vue&type=script&lang=js&"
export * from "./NavigationMobileMain.vue?vue&type=script&lang=js&"
import style0 from "./NavigationMobileMain.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationSearch: require('/usr/src/app/components/Navigation/NavigationSearch.vue').default,NavigationMobileLevel2: require('/usr/src/app/components/Navigation/Mobile/NavigationMobileLevel2.vue').default,LanguageSwitch: require('/usr/src/app/components/LanguageSwitch.vue').default})
