//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'NavigationMobileUtils',
    computed: {
        ...mapGetters(['getStoredSiteProperties', 'getStoredPage']),
    },
    data() {
        return {
            utils: null,
        };
    },
    mounted() {
        try {
            this.utils = this.getStoredSiteProperties?.meta?.utils;
        } catch (e) {
            console.error(this.utils);
        }
    },
};
