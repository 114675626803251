//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'NavigationMobileMain',
    data() {
        return {
            expanded: false,
            opened: 1,
            menuLevel: 1,
            option: null,
        };
    },
    computed: {
        ...mapGetters(['getStoredMenu']),
        getStoredMenuTop() {
            return this.getStoredMenu[this.$i18n.locale].children.filter((item) => item.navigationPosition === 'left');
        },
        getStoredMenuBottom() {
            return this.getStoredMenu[this.$i18n.locale].children.filter((item) => item.navigationPosition === 'right');
        },
    },
    mounted() {
        this.$nuxt.$on('mobile-nav.close', () => {
            this.expanded = false;
            this.option = null;
        });

        this.$nuxt.$on('mobile-nav.open', (data) => {
            this.expanded = true;
            this.option = data ? data.option : null;
        });

        this.$nuxt.$on('mobile-nav.level', ({ level }) => {
            this.menuLevel = level;
        });
    },
    methods: {
        handleSubmenu(event) {
            this.opened = event.target.getAttribute('id');
            this.$nuxt.$emit('mobile-nav.level2.open', {
                element: event.target.parentNode.querySelector('.navigation-mobile-level2'),
            });
        },
    },
};
