var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getStoredMenu[_vm.$i18n.locale])?_c('div',{staticClass:"navigation-mobile-main",class:[{ 'navigation-mobile-main--active': _vm.expanded }],attrs:{"data-menu-level":_vm.menuLevel}},[_c('div',{staticClass:"navigation-mobile-main__animationlayer"},[(_vm.option === 'search')?_c('div',[_c('navigation-search',{attrs:{"mobile":true}})],1):_c('div',{staticClass:"navigation-mobile-main__items flex flex-col gap-md"},[_c('ul',{staticClass:"flex flex-col"},_vm._l((_vm.getStoredMenuTop),function(page,index){return (page.hidden !== true && page.visible)?_c('li',{key:index,staticClass:"navigation-mobile-main__item"},[(page.children.length)?[_c('button',{staticClass:"navigation-mobile-main__link",class:{
                                'navigation-mobile-main__link--active': page.active,
                                'navigation-mobile-main__link--open': _vm.opened === page.id,
                                'navigation-mobile-main__link--hasflyout': page.flyout || page.children,
                            },attrs:{"id":page.id,"title":page.navtitle || page.title},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmenu($event)}}},[_vm._v("\n                            "+_vm._s(page.navtitle || page.title)+"\n                        ")]),_vm._v(" "),_c('navigation-mobile-level-2',{attrs:{"page":page,"flyout":page.flyout,"subpages":page.children,"headline":page.navtitle || page.title}})]:_c('a',{staticClass:"link navigation-mobile-main__link",attrs:{"href":_vm.$localizeSlug(page.slug),"title":page.navtitle || page.title}},[_vm._v(_vm._s(page.navtitle || page.title))])],2):_vm._e()}),0),_vm._v(" "),_c('ul',{staticClass:"flex flex-col"},[_vm._l((_vm.getStoredMenuBottom),function(page,index){return (page.hidden !== true && page.visible)?_c('li',{key:index,staticClass:"navigation-mobile-main__item"},[(page.children.length)?[_c('button',{staticClass:"navigation-mobile-main__link",class:{
                                'navigation-mobile-main__link--active': page.active,
                                'navigation-mobile-main__link--open': _vm.opened === page.id,
                                'navigation-mobile-main__link--hasflyout': page.flyout || page.children,
                            },attrs:{"id":page.id,"title":page.navtitle || page.title},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmenu($event)}}},[_vm._v("\n                            "+_vm._s(page.navtitle || page.title)+"\n                        ")]),_vm._v(" "),_c('navigation-mobile-level-2',{attrs:{"page":page,"flyout":page.flyout,"subpages":page.children,"headline":page.navtitle || page.title}})]:_c('a',{staticClass:"link navigation-mobile-main__link pt-0",attrs:{"href":_vm.$localizeSlug(page.slug),"title":page.navtitle || page.title}},[_vm._v(_vm._s(page.navtitle || page.title))])],2):_vm._e()}),_vm._v(" "),_c('li',{staticClass:"navigation-mobile-main__item pt-4"},[_c('language-switch',{attrs:{"isMobile":true}})],1)],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }