//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Navigation from './Navigation/Navigation.vue';
import NavigationMobile from './Navigation/Mobile/NavigationMobile.vue';

export default {
    name: 'HeaderComponent',
    components: { NavigationMobile, Navigation },
    props: {
        light: {
            default: false,
            type: Boolean,
        },
        isSticky: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            collapsed: false,
            scrollTimeout: 0,
            scrollY: 0,
            isLight: true,
            transitioning: false,
            navOpen: false,
        };
    },
    created() {
        // set light mode of header
        this.isLight = this.light;
    },
    mounted() {
        // set headers height in custom property
        this.updateHeaderHeightVar();

        // listen to scrollevent to collapse header
        window.addEventListener('scroll', this.onScroll, { passive: true });
        window.addEventListener('resize', this.updateHeaderHeightVar, { passive: true });

        // listen to transitionstart, -end event of header element

        this.$refs.header.addEventListener('transitionstart', () => {
            this.transitioning = true;
        });
        this.$refs.header.addEventListener('transitionend', () => {
            this.transitioning = false;
        });

        // listen to header.state event for changes light = true || false
        this.$nuxt.$on('header.state', ({ light }) => {
            // switch state only if not equal
            if (this.isLight !== light) {
                // set new state value
                this.isLight = light;
            }
        });
        this.$nuxt.$on('navigation.state', ({ navOpen }) => {
            this.navOpen = navOpen;
        });

        // window.addEventListener('focusMetaLink', () => {
        //     this.collapsed = false;
        // });
    },
    methods: {
        updateHeaderHeightVar() {
            window.requestAnimationFrame(() => {
                document.documentElement.style.setProperty('--header-height', `${this.$el.getBoundingClientRect().height}px`);
                document.documentElement.style.setProperty('--navmeta-height', `${document.querySelector('.navigationmeta').getBoundingClientRect().height}px`);
            });
        },

        stateLanguageBanner(val) {
            setTimeout(this.updateHeaderHeightVar, 500);
        },
        /**
         * Handle scroll event to collapse or expand header component
         */
        onScroll() {
            const sy = Math.max(this.scrollY - 1, 0);
            this.scrollY = window.pageYOffset;
            const langBannerHeight = this.$refs.langBanner?.getBoundingClientRect()?.height;
            setTimeout(() => {
                this.collapsed = this.isSticky && sy < this.scrollY && this.scrollY > parseInt(langBannerHeight);
            }, 1);
        },
    },
};
