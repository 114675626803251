//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationSubmenuFlyout from './NavigationSubmenuFlyout.vue';
import SubmenuLink from './SubmenuLink.vue';

export default {
    name: 'NavigationSubmenu',
    components: { SubmenuLink, NavigationSubmenuFlyout },
    props: {
        subpages: {
            type: Array,
            default: () => [],
        },
        headline: {
            type: String,
            default: null,
        },
        flyout: {
            type: Array,
            default: null,
        },
        page: {
            type: Object,
            default: null,
        },
        isSearch: {
            tape: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expanded: false,
            subFlyoutId: 0,
            bs: null,
            lastFocusedElement: null,
        };
    },
    computed: {
        visibleSubpages() {
            return this.subpages.filter((sub) => sub.hidden !== true && sub.visible);
        },
    },
    watch: {
        expanded(val) {
            if (val) {
                return;
            } else {
                // this.bs(true);
                this.$nuxt.$emit('submenu.closed', { id: this.page?.id || 'search' });
                this.$nuxt.$emit('submenu.flyout.toggle', { toggle: 0 });
                this.$emit('submenu-closed');
            }
        },
        subFlyoutId(newId, oldId) {
            if (newId !== 0) {
                this.$nextTick(() => {
                    const firstFocusableElement = this.$el.querySelector(`#submenu-flyout-${newId} a, #submenu-flyout-${newId} button`);
                    if (firstFocusableElement) {
                        firstFocusableElement.focus();
                    }
                });
            } else if (this.lastFocusedElement) {
                this.lastFocusedElement.focus();
            }
        },
    },
    created() {
        // this.initBodyScroll();
        this.initEventListeners();
    },
    methods: {
        // async initBodyScroll() {
        //     if (process.client) {
        //         const { bodyScroll } = await import('../../assets/js/preventBodyScroll.js');
        //         this.bs = bodyScroll;
        //     }
        // },
        initEventListeners() {
            this.$nuxt.$on('submenu.toggle', ({ toggle }) => {
                this.expanded = toggle === this.$el || false;
            });
            this.$nuxt.$on('submenu.flyout.toggle', ({ toggle }) => {
                this.subFlyoutId = this.subpages.some((item) => item.id === toggle) ? toggle : 0;
            });
        },
        closeSubmenu() {
            this.expanded = false;
            this.$nuxt.$emit('submenu-closed');
        },
        closeSubFlyout() {
            this.$nuxt.$emit('submenu.flyout.toggle', { toggle: 0 });
        },
        toggleSubFlyout(id, event) {
            this.$nuxt.$emit('submenu.flyout.toggle', { toggle: this.subFlyoutId === id ? 0 : id });
        },
        hasChildren(sub) {
            return (sub.children && sub.children.length && this.subLinksVisible(sub.children)) || (sub.flyout && sub.flyout.length);
        },
        subLinksVisible(subPages) {
            return subPages.some((subPage) => subPage.hidden !== true);
        },
        handleKeyDown(event) {
            if (event.key === 'Escape' && this.expanded) {
                this.closeSubmenu();
                return;
            }
            if (event.key === 'Tab' && event.shiftKey) {
                const activeElement = document.activeElement;
                if (activeElement && activeElement.classList.contains('submenulink--open')) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (activeElement.parentElement.previousElementSibling) {
                        activeElement.parentElement.previousElementSibling.querySelector('a').focus();
                        return false;
                    }
                }
            } else if (event.key === 'Tab') {
                if (event.shiftKey && this.subFlyoutId !== 0) {
                    const currentFlyout = this.$el.querySelector(`#submenu-flyout-${this.subFlyoutId}`);
                    if (currentFlyout && !currentFlyout.contains(event.target)) {
                        event.preventDefault();
                        if (this.lastFocusedElement) {
                            this.lastFocusedElement.focus();
                        }
                    }
                }
            }
        },
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);

        this.$nuxt.$on('sub-menu.close', () => {
            this.closeSubmenu();
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    },
};
