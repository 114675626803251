import { render, staticRenderFns } from "./HeaderComponent.vue?vue&type=template&id=128e1fb7&"
import script from "./HeaderComponent.vue?vue&type=script&lang=js&"
export * from "./HeaderComponent.vue?vue&type=script&lang=js&"
import style0 from "./HeaderComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageBanner: require('/usr/src/app/components/LanguageBanner.vue').default,Navigation: require('/usr/src/app/components/Navigation/Navigation.vue').default,NavigationMobile: require('/usr/src/app/components/Navigation/Mobile/NavigationMobile.vue').default})
